<template>
  <b-container class="py-5">
    <div class="text-center p-5 m-5">
      <h1>{{ massage }}</h1>
      <vac
        ref="resetPassword_countdown"
        :autoStart="false"
        :left-time="5 * 1000"
        @finish="finish()"
      >
        <template v-slot:process="{ timeObj }">
          <h6>
            {{ `You will be redirect within ${timeObj.s} seconds!` }}
          </h6>
        </template>
        <template v-slot:finish>
          <h3>You are now redirect!</h3>
        </template>
      </vac>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      massage: null
    };
  },
  methods: {
    async verify() {
      try {
        await axios.post("SP/post/User_Register_Verify", {
          emailtoken: this.$route.params.id
        });
        this.massage = "You have been sucesssful registered!";
      } catch (error) {
        this.massage = error.response.data;
        console.log(error.response.data);
      }
    },
    finish() {
      this.$router.push({ name: "Home" });
    }
  },
  async mounted() {
    this.verify();
  },
  beforeCreate() {
    document.getElementById("footer").style.display = "none";
    document.getElementById("socket").style.display = "none";
  },
  beforeDestroy() {
    document.getElementById("footer").style.display = "block";
    document.getElementById("socket").style.display = "block";
  }
};
</script>
